import './jquery-ui';

class DatePicker {
  constructor() {
    $('.datepicker').datepicker()
      .datepicker('option', $.datepicker.regional.en)
      .datepicker('option', { 'dateFormat': 'yy-mm-dd', firstDay: 0 })
    $('.datepicker').each(function(i, e) { var $e = $(e); $e.val($e.attr('value')) });
  }
}

export default DatePicker;
