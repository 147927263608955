// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"

import './src/jquery'

import DatePicker from './src/datepicker'

import $ from 'jquery'
import Modal from './src/modal'
import ModalPlayer from './src/modal-player'
import Fader from './src/fader'
import DateUtil from "./src/date-util";

window.du = new DateUtil()
window.$ = $

document.addEventListener('turbo:load', function() {
  dataLayer.push({ event: 'pageView', virtualUrl: window.location.href }); // so GTM tags can fire on link navigation

  $('.hamburger').on('click', function(e) {
    e.stopPropagation();
    $(this).toggleClass('hamburger__change');
    if ($(this).hasClass('hamburger__change'))
      $('.mega-menu').slideDown();
    else
      $('.mega-menu').slideUp();
  });

  let $hs = $('[data-hubspot-form-id]');
  if ($hs.length > 0) {
    $hs.find('iframe').remove();
    hbspt.forms.create({
      portalId: "4395239",
      formId: $hs.data('hubspot-form-id'),
      target: '#hs-form'
    });
  }

  fader = new Fader($('#fader'), $('#fader-dots'), 7000);
  document.addEventListener("turbo:before-cache", function() {
    fader.stop()
  }, {once: true})

  new ModalPlayer();
  new Modal();
  new DatePicker();
});
