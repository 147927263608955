import $ from 'jquery'

class Modal {
  constructor() {
    $('._pick').on('click', (e) => {
      e.stopPropagation();
      let $modal = $(e.currentTarget).parent().next('.pick-details'),
      $container = $modal.find('.pick-details__container');
      $modal.show();
      $container.css('margin-top', (document.documentElement.clientHeight - parseFloat($container.css('height'))) / 2 + 'px');
    });
    $('.pick-details').on('click', (e) => { e.stopPropagation(); $(e.currentTarget).hide(); });
    $('.pick-details__container').on('click', (e) => e.stopPropagation());
  }
}

export default Modal;
