import $ from 'jquery'

class Fader {

  scheduleFade() {
    this.autoFadeTimer = setTimeout(() => {
      this.handleNext();
      this.scheduleFade();
    }, this.autoFadeInterval);
  }

  constructor($container, $dotContainer, autoFadeInterval) {
    this.$items = $container.find('._fader-item');
    this.$dotContainer = $dotContainer;
    this.$dots = $dotContainer.find('._fader-dot');
    this.activeItem = 0;
    this.numItems = this.$items.length;
    this.$dots.on('click', (e) => this.handleDot(e))
    this.autoFadeInterval = autoFadeInterval;
    if (autoFadeInterval) this.scheduleFade();
  }

  handleDot(e) {
    e.stopPropagation();
    var $target = $(e.target);
    var from = this.activeItem,
        to = $target.data('index');
    if (from == to) return;
    this.fade(from, to);
  }

  handleNext() {
    if (this.numItems < 2) return;
    var from = this.activeItem,
        to = (from + 1) % this.numItems;
    this.fade(from, to);
  }

  fade(from, to) {
    if (this.autoFadeInterval) this.stop();
    this.$dots.eq(from).removeClass('active');
    this.$dots.eq(to).addClass('active');
    this.$items.eq(from).removeClass('active');
    this.$items.eq(to).addClass('active');
    this.activeItem = to;
  }

  stop() {
    clearTimeout(this.autoFadeTimer);
  }

}

export default Fader;
