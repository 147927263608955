class DateUtil {

  constructor() {
    this.minuteMillis = 60 * 1000;
    this.hourMillis = 60 * this.minuteMillis;
    this.dayMillis = 24 * this.hourMillis;
    this.dayNames = {
      en: { 0: 'Sunday', 1: 'Monday', 2: 'Tuesday', 3: 'Wednesday', 4: 'Thursday', 5: 'Friday', 6: 'Saturday' },
      es: { 0: 'Domingo', 1: 'Lunes', 2: 'Martes', 3: 'Miércoles', 4: 'Jueves', 5: 'Viernes', 6: 'Sábado' },
    };
    this.monthNames = {
      en: {
        0: 'January', 1: 'February', 2: 'March', 3: 'April', 4: 'May', 5: 'June',
        6: 'July', 7: 'August', 8: 'September', 9: 'October', 10: 'November', 11: 'December'
      },
      es: {
        0: 'Enero', 1: 'Febrero', 2: 'Marzo', 3: 'Abril', 4: 'Mayo', 5: 'Junio',
        6: 'Julio', 7: 'Agosto', 8: 'Septiembre', 9: 'Octubre', 10: 'Noviembre', 11: 'Diciembre'
      }
    };
  }

  dstChange(beginDate, endDate) {
    let startDate = beginDate,
        stopDate = endDate,
        offsetBefore = -beginDate.getTimezoneOffset(),
        offsetAfter = -endDate.getTimezoneOffset(),
        at = beginDate,
        midDate,
        beginMillis, endMillis, diffMillis, tzBefore, tzAfter;

    if (offsetBefore != offsetAfter) {
      beginMillis = beginDate.getTime(); endMillis = endDate.getTime(); diffMillis = endMillis - beginMillis;
      while (diffMillis > this.hourMillis) {
        midDate = new Date(beginMillis + (endMillis - beginMillis) / 2);
        if (midDate.getTimezoneOffset() == beginDate.getTimezoneOffset()) {
          beginDate = midDate;
          beginMillis = beginDate.getTime();
        } else {
          endDate = midDate;
          endMillis = endDate.getTime();
        }
        diffMillis = endMillis - beginMillis;
      }
      at = new Date(endMillis - endMillis % this.hourMillis);
    }

    tzBefore = startDate.toString().match(/\((.*)\)/);
    if (tzBefore) {
      tzBefore = tzBefore[1].replace(/[a-z\s]/g, '');
      tzAfter = stopDate.toString().match(/\((.*)\)/)[1].replace(/[a-z\s]/g, '');
    } else {
      tzBefore = startDate.toString().match(/(GMT-\d{4})/);
      if (tzBefore) {
        tzBefore = tzBefore[1];
        tzAfter = stopDate.toString().match(/(GMT-\d{4})/)[1];
      } else {
        tzBefore = tzAfter = 'Local';
      }
    }

    let diff = offsetAfter - offsetBefore;
    return {
      from: at,
      to: new Date(at.getTime() - Math.abs(diff) * this.minuteMillis),
      diff: diff,
      timezoneBefore: tzBefore,
      timezoneAfter: tzAfter
    };
  }

  fill2(n) {
    return (n < 10 ? '0' : '') + n;
  }

  amPm(hours) {
    return hours > 11 ? 'PM' : 'AM';
  }

  civilian(hours) {
    return hours > 12 ? hours - 12 : hours || 12;
  }

  formatDate(date, format, language) {
    let month = date.getMonth(),
        day = date.getDate(),
        weekday = date.getDay(),
        year = date.getFullYear();
    if ('es' == language) {
      switch (format) {
        case 'simple':
          return `${year}-${this.fill2(month + 1)}-${this.fill2(day)}`;
        case 'full':
          return `${this.dayNames.es[weekday]} ${day} de ${this.monthNames.es[month]} de ${year}`;
        case 'short':
          return `${day} ${this.monthNames.es[month].slice(0, 3)} ${year}`;
        case 'medium':
          return `${day} ${this.monthNames.es[month]} ${year}`;
        case 'informal':
          return `${this.dayNames.es[weekday]} ${day} de ${this.monthNames.es[month]}`;
        case 'widget':
          return `${this.fill2(day)} ${this.monthNames.es[month].slice(0, 3)}`;
      }
    } else {
      switch (format) {
        case 'simple':
          return `${year}-${this.fill2(month + 1)}-${this.fill2(day)}`;
        case 'full':
          return `${this.dayNames.en[weekday]}, ${this.monthNames.en[month]} ${day}, ${year}`;
        case 'short':
          return `${this.monthNames.en[month].slice(0, 3)} ${day}, ${year}`;
        case 'medium':
          return `${this.monthNames.en[month]} ${day}, ${year}`;
        case 'informal':
          return `${this.dayNames.en[weekday]}, ${this.monthNames.en[month]} ${day}`;
        case 'widget':
          return `${this.fill2(month + 1)}/${this.fill2(day)}`;
      }
    }
  }

  formatTime(date) {
    let hours = date.getHours(),
        minutes = date.getMinutes();
    return `${this.civilian(hours)}:${this.fill2(minutes)} ${this.amPm(hours)}`;
  }

  formatWeekTime(date, language) {
    return `${this.dayNames[language][date.getDay()]} ${this.formatTime(date)}`;
  }

  formatWeekDay(date, number, language) {
    let wday = date.getDay(),
        plural = 'plural' == number && ('en' == language || 0 == wday || 6 == wday) ? 's' : '';
    return `${this.dayNames[language][wday]}${plural}`;
  }

  formatDateTime(date, format, language) {
    let hours = date.getHours(),
    timeAt = 'widget' == format ? ' &mdash; ' : ('es' == language ? ' a la' + (1 == hours ? ' ' : 's ') : ' at ');
    return this.formatDate(date, format, language) + timeAt + this.formatTime(date);
  }
}

export default DateUtil;
